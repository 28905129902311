import React, { Fragment } from 'react';
// import { Link } from 'gatsby';
import Layout from '../components/layoutProfile';
import SubHeader from '../components/SubHeaderProfile';
// import * as ROUTES from '../src/constants/routes';

class SakaiPage extends React.Component {
  constructor(props) {
    super(props);
    // this.handleScroll = this.handleScroll.bind(this);
    // window.addEventListener('scroll', this.handleOnScroll, true);
    this.state = {
        device: 'pc',
        theposition:null,
        // handleScroll:null,
    };
}
componentDidMount() {

// this.props.getCards('default');
window.addEventListener('scroll', this.handleOnScroll)

    const ua = window.navigator.userAgent.toLowerCase();
    if (ua.indexOf('iphone') > 0 || ua.indexOf('ipod') > 0 || ua.indexOf('android') > 0 && ua.indexOf('mobile') > 0) {
        this.setState({ device: 'sp' });
    } else {
        this.setState({ device: 'pc' });
    }
}

jiko() {
  window.location.href="#jiko";
}
jiko2() {
  window.location.href="#jiko2";
}
haikei() {
window.location.href="#haikei";
}
daiji() {
window.location.href="#daiji";
}


  render() {
    return (
      <Fragment>
      <title>監督 酒井良幸 國學院大學久我山高等学校 バスケットボール部</title>
  <div className="sakai-background">

  <div className="profile-container">
  <div className="profile-area"><a name="jiko"></a>
  <h2 className="content-title-white-profile">自己紹介</h2>
      {this.state.device =="sp" && (
<div className="profile-maintext">
<div>名前：酒井 良幸</div><div>(さかい よしゆき)</div>
<div>生まれ：東京都</div>
<div>出身中学校：大田区立貝塚中学校</div>
<div>出身高校：國學院大學久我山高校</div>
<div>大学：日本体育大学</div>
<div>専門学校：日本鍼灸理療専門学校</div>
<div>勤務先：錦城学園高校～國學院久我山高校</div>
  </div>
)}
{this.state.device =="pc" && (
<div className="profile-maintext">
<div>名前：酒井 良幸  (さかい よしゆき)</div>
<div>生まれ：東京都</div>
<div>出身中学校：大田区立貝塚中学校</div>
<div>出身高校：國學院大學久我山高校</div>
<div>大学：日本体育大学</div>
<div>専門学校：日本鍼灸理療専門学校</div>
<div>勤務先：錦城学園高校～國學院久我山高校</div>
</div>
)}
<div className="arrowWrap" onClick={this.jiko2}>
  <div className="arrow1">
    <span></span>
  </div>
  <div className="arrow2">
    <span></span>
  </div>
</div>
</div>


<div className="profile-area-main" id="jiko2">
<h2 className="content-title-white-profile">これまでの経歴</h2>
{this.state.device =="sp" && (
  <div className="profile-maintext">
  中学校からバスケを始め、中学２年時に國學院久我山高校バスケ部の練習体験に参加し、肌でレベルの高さを体感。入学への思いを大きく膨らませた。國學院久我山高校バスケ部の3年時に副キャプテンを務め、インターハイ出場を経験。日本体育大学バスケ部では1軍のトレーナーとして、4年間でインカレ優勝2回、準優勝2回を経験。2006年にアシスタントコーチとして恩師の手塚政則先生と共にチームに関わる。2008年、國學院久我山高校の専任教諭、またバスケ部の監督に就任。その後インターハイ出場8回、ウィンターカップ出場3回を経験。最高順位は、2013年ウィンターカップベスト8。
  </div>
)}
{this.state.device =="pc" && (
  <div>
  <div className="profile-maintext">
  中学校からバスケを始め、中学２年時に國學院久我山高校バスケ部の練習体験に参加し、肌でレベルの高さを体感。入学への思いを大きく膨らませた。國學院久我山高校バスケ部では、３年時に副キャプテンを務め、インターハイ出場を経験。大学では、日本体育大学バスケ部の１軍のトレーナーとして、４年間でインカレ優勝２回、準優勝２回を経験。
  </div>
  <div className="profile-maintext">
  　大学卒業から４年後の２００６年に、アシスタントコーチとして恩師、手塚政則先生と共にチームに関わる。２００８年、國學院久我山高校の専任教諭、またバスケ部の監督に就任。これまで監督として、インターハイ出場８回、ウィンターカップ出場３回を経験。最高順位は、２０１３年ウィンターカップベスト８。
  </div>
  </div>
)}
<div className="arrowWrap" onClick={this.haikei}>
  <div className="arrow1">
    <span></span>
  </div>
  <div className="arrow2">
    <span></span>
  </div>
</div>
</div>

<div className="profile-area-main" id="haikei">
<h2 className="content-title-white-profile">監督就任背景</h2>
<div className="profile-maintext">
  中学時代から体育教師になる事が夢であり、高校時代から母校である國學院久我山高校でその夢を叶えたいと強く想う。</div>
<div className="profile-maintext">
  大学卒業時にはスポーツトレーナーを目指すも夢を諦めきれず、大学卒業してから４年後の２００６年に、前監督であり、恩師でもある手塚政則先生からの誘いを受け、國學院久我山高校に奉職しバスケ部コーチを歴任、２００８年にはバスケ部監督に就任した。
</div>
<div className="arrowWrap" onClick={this.daiji}>
  <div className="arrow1">
    <span></span>
  </div>
  <div className="arrow2">
    <span></span>
  </div>
</div>
</div>

<div className="profile-area-main" id="daiji">
<h2 className="content-title-white-profile">中学生・保護者の皆様へ</h2>
<div>何のためにバスケットをやっているのか、何のために日本一を目指しているのか、何のために生きているのか、志や使命を持って社会に大きく貢献して欲しいという願いから、社会で必要とされる人財を輩出することに重点を置き、日々バスケットボールを通じて生徒と向き合っている。</div>
<div className="video-adjust">
<iframe className="profile-video" id="ytplayer" type="text/html" width="640" height="360"
  src="https://www.youtube.com/embed/yjmN79e8RaE"
  frameBorder="0"></iframe>
  </div>
</div>
</div>
  </div>
    </Fragment>
);
}
}

export default () => (
  <Layout>
  <SubHeader />
  <SakaiPage />
  </Layout>
);
